<template>
  <v-card class="mx-auto mt-5 mb-5" elevation="4" max-width="600">
    <v-card-title class="justify-center">Account</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-card class="mb-5" elevation="2">
        <v-card-text>
          <v-form ref="form" class="pt-1" v-model="valid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="user.name"
                  :rules="req"
                  label="Name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  data-cy="user.name-input"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="user.email"
                  label="E-mail"
                  placeholder="E-mail"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  data-cy="user.email-input"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.phone"
                  label="Phone Number"
                  type="tel"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  autocomplete="tel"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.street"
                  label="Address Line"
                  autocomplete="shipping street-address"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="user.city"
                  label="City"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  autocomplete="shipping locality"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="user.state"
                  :items="all_states"
                  label="State"
                  item-value="abbreviation"
                  item-text="name"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  autocomplete="shipping region"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="user.zip"
                  label="ZIP / Postal Code"
                  dense
                  :loading="loading"
                  :disabled="loading"
                  :rules="req"
                  autocomplete="shipping postal-code"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="themeSwitch()" v-if="$vuetify.theme.dark">
            Light Mode
          </v-btn>
          <v-btn @click="themeSwitch()" v-else>Dark Mode</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="update">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- <v-card class="mt-5 mb-5" elevation="2">
        <v-card-title class="justify-center">
          Reset Password
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newPassword"
                label="New Password"
                type="password"
                dense
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="confirmPassword"
                label="Confirm Password"
                type="password"
                dense
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="reset">
            Reset
          </v-btn>
        </v-card-actions>
      </v-card> -->
    </v-card-text>

    <v-expansion-panels class="px-4 py-3">
      <v-expansion-panel v-for="(item, i) in 1" :key="i">
        <v-expansion-panel-header>
          Seminar
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div style="position: relative; padding-top: 56.25%;">
            <iframe
              src="https://iframe.videodelivery.net/fcd5fb7d39d4609c88837f0d3eac17d0"
              style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            ></iframe>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- <v-card-text>
      <v-row>
        <v-col cols="12">
          <proposal-table></proposal-table>
        </v-col>
      </v-row>
    </v-card-text> -->
  </v-card>
</template>

<script>
// import ProposalTable from "../components/Shared/ProposalTable.vue";
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "Account",
  mixins: [hasuraService],

  components: {
    // ProposalTable,
  },
  mounted() {
    // this.getUser();
    this.get_user();
  },

  methods: {
    async get_user() {
      this.loading = true;
      this.user = await this.get_user_by_pk(this.$store.state.user.id);
      this.loading = false;
    },
    async reset() {
      if (this.newPassword == this.confirmPassword) {
        this.loading = true;
        let result = await this.passwordChangeAccount(this.newPassword);
        this.loading = false;
        if (result.ok) {
          // success
          this.$store.commit("set_snackbar", "Password Updated!");
        } else {
          // error
          this.$store.commit(
            "set_snackbar",
            "Server Error, please contact matt@annuitycheck.com"
          );
        }
      } else {
        // bad password
        this.$store.commit(
          "set_snackbar",
          "Passwords do not match, please try again"
        );
      }
    },
    update: async function() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.update_user_by_pk(this.$store.state.user.id, this.user);
        this.$store.commit("set_snackbar", "Saved!");
        this.loading = false;
      }
    },
    themeSwitch() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.dark = this.$vuetify.theme.dark + "";
    },
  },
  computed: {
    primary_color: {
      get() {
        return localStorage.primary_color || "#22ABE1";
      },
      set(value) {
        localStorage.primary_color = value;
        this.$vuetify.theme.themes.light.primary = value;
        this.$vuetify.theme.themes.dark.primary = value;
      },
    },
  },

  data() {
    return {
      req: [(v) => !!v || "Field is required"],
      loading: true,
      newPassword: "",
      confirmPassword: "",
      valid: true,
      user: {
        name: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      all_states: [
        { name: "ALABAMA", abbreviation: "AL" },
        { name: "ALASKA", abbreviation: "AK" },
        { name: "ARIZONA", abbreviation: "AZ" },
        { name: "ARKANSAS", abbreviation: "AR" },
        { name: "CALIFORNIA", abbreviation: "CA" },
        { name: "COLORADO", abbreviation: "CO" },
        { name: "CONNECTICUT", abbreviation: "CT" },
        { name: "DELAWARE", abbreviation: "DE" },
        { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
        { name: "FLORIDA", abbreviation: "FL" },
        { name: "GEORGIA", abbreviation: "GA" },
        { name: "HAWAII", abbreviation: "HI" },
        { name: "IDAHO", abbreviation: "ID" },
        { name: "ILLINOIS", abbreviation: "IL" },
        { name: "INDIANA", abbreviation: "IN" },
        { name: "IOWA", abbreviation: "IA" },
        { name: "KANSAS", abbreviation: "KS" },
        { name: "KENTUCKY", abbreviation: "KY" },
        { name: "LOUISIANA", abbreviation: "LA" },
        { name: "MAINE", abbreviation: "ME" },
        { name: "MARYLAND", abbreviation: "MD" },
        { name: "MASSACHUSETTS", abbreviation: "MA" },
        { name: "MICHIGAN", abbreviation: "MI" },
        { name: "MINNESOTA", abbreviation: "MN" },
        { name: "MISSISSIPPI", abbreviation: "MS" },
        { name: "MISSOURI", abbreviation: "MO" },
        { name: "MONTANA", abbreviation: "MT" },
        { name: "NEBRASKA", abbreviation: "NE" },
        { name: "NEVADA", abbreviation: "NV" },
        { name: "NEW HAMPSHIRE", abbreviation: "NH" },
        { name: "NEW JERSEY", abbreviation: "NJ" },
        { name: "NEW MEXICO", abbreviation: "NM" },
        { name: "NEW YORK", abbreviation: "NY" },
        { name: "NORTH CAROLINA", abbreviation: "NC" },
        { name: "NORTH DAKOTA", abbreviation: "ND" },
        { name: "OHIO", abbreviation: "OH" },
        { name: "OKLAHOMA", abbreviation: "OK" },
        { name: "OREGON", abbreviation: "OR" },
        { name: "PALAU", abbreviation: "PW" },
        { name: "PENNSYLVANIA", abbreviation: "PA" },
        { name: "PUERTO RICO", abbreviation: "PR" },
        { name: "RHODE ISLAND", abbreviation: "RI" },
        { name: "SOUTH CAROLINA", abbreviation: "SC" },
        { name: "SOUTH DAKOTA", abbreviation: "SD" },
        { name: "TENNESSEE", abbreviation: "TN" },
        { name: "TEXAS", abbreviation: "TX" },
        { name: "UTAH", abbreviation: "UT" },
        { name: "VERMONT", abbreviation: "VT" },
        { name: "VIRGIN ISLANDS", abbreviation: "VI" },
        { name: "VIRGINIA", abbreviation: "VA" },
        { name: "WASHINGTON", abbreviation: "WA" },
        { name: "WEST VIRGINIA", abbreviation: "WV" },
        { name: "WISCONSIN", abbreviation: "WI" },
        { name: "WYOMING", abbreviation: "WY" },
      ],
    };
  },
};
</script>
